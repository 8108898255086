<template>
  <div class="row mx-0 white_bg">
    <div class="col-12 py-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 text-center fs-1 mb-3">
            {{currentPackage.name}}
          </div>
          <div class="col-10">
            <div class="row justify-content-center">
              <div class="col-12 mb-2 text-center" v-for="(feature, index) in currentPackage.features" :key="index">
                {{feature}}
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="row">
              <div class="col-12 text-center my-3" v-if="currentPackage.price.annual">
                <span class="fs-4 fw-bold">R {{currentPackage.price.annual}}</span> annual
              </div>
              <div class="col-12 text-center mb-3" v-if="currentPackage.price.onceOff && currentPackage.price.annual">
                <span class="fs-4 fw-bold" >
                  <span v-if="(typeof(currentPackage.price.onceOff)) !== 'string' ">R </span>
                  {{currentPackage.price.onceOff}}
                </span> once off
                <span v-if="(typeof(currentPackage.price.onceOff)) === 'string' "> costs</span>
              </div>
              <div class="col-12 text-center mb-3" v-if="currentPackage.price.onceOff && !currentPackage.price.annual">
                Costs
                <span class="fs-4 fw-bold" >
                  {{currentPackage.price.onceOff}}
                </span>
              </div>
            </div>
          </div>
          <div class="col-12 text-center my-3">
            <div class="row justify-content-center align-items-center">
              <div class="col-6">
                <button class="btn btn-blue fw-bold" @click="getStarted(currentPackage.key)" v-if="currentPackage.price.onceOff && currentPackage.price.annual">
                  Get started
                </button>
                <button class="btn btn-blue fw-bold" @click="getMeeting" v-else>
                  Set up a meeting
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-12 col-md-10 mb-3" v-for="(detail, key) in currentPackage.details" :key="key">
            <div class="row">
              <div class="col-12 fw-bold blue_font text-capitalize">
                {{key}}
              </div>
              <div class="col-12" v-html="detail">
              </div>
            </div>
          </div>
          <div class="col-12 text-center">
            <button class="btn btn-blue fw-bold" @click="getStarted(currentPackage.key)" v-if="currentPackage.price.onceOff && currentPackage.price.annual">
              Get started
            </button>
            <button class="btn btn-blue fw-bold" @click="getMeeting" v-else>
              Set up a meeting
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PackageOption',
  data () {
    return {
      packages: require('@/static/packages.json')
    }
  },
  computed: {
    packageType () {
      return parseInt(this.$route.params.id)
    },
    currentPackage () {
      return this.packages.find(item => {
        if (item.key !== this.packageType) {
          return false
        }
        return true
      })
    }
  },
  methods: {
    getStarted (val) {
      window.scrollTo(0, 0)
      this.$router.push('/signup/' + val)
    },
    getMeeting () {
      window.open('https://calendly.com/tim-simplest/45min', '_blank')
    }
  }
}
</script>
<style scoped>

</style>
